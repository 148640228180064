import React from 'react';
import {Navbar, Nav, Container/*, DropdownButton, Dropdown*/} from 'react-bootstrap'
import { GoogleSpreadsheet } from "google-spreadsheet";
import Clock from 'react-live-clock';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import loadingImageDesktop from './media/loadDesktop.png';
import loadingImageMobile from './media/loadMobile.png';
import one from './media/001.jpg';
import two from './media/002.jpg';
import three from './media/003.jpeg';
import four from './media/004.jpg';
import five from './media/005.jpg';

import './Home.css';

//LOOK UP HOW TO LOOP ANIMATION, AND ALSO HOW TO TIME ANIMATION SO THAT IT CORRESPONDS TO THE TIME THAT SOMETHING LOADS, SEE WHEN AN IMAGE
//HAS BECOME FULLY VISIBLE 

var unfilteredArray = []
var filteredArray = []
var renderIntake = []

var settings = {
  dots: true,
  infinite: true,
  speed: 300,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SPREADSHEET_ID = "1HWPDbItdCiM973f9z3nc3HuTeV0gaB6UwZp2o8lgzOU";
const CLIENT_EMAIL = "admin-196@nickchaseworld.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCliyWc9Cc/DIA3\nSXwkFDh8ARGwXfZCS2ptr/hVkxd3RgiRvJP0rKLXjcQo2UmdKzkvR6kKCypzzPt9\nCHQMP5SQQeISF2ul5lAvQLHHGuGGqjPC0L9JZPuJ9UrKCMqwKvukKQf14fntujAm\n2I93rHM6tPgClT8zKUvts/nn6bMigpAV9g+vpjlt9qHGnuYV5lBhwATleWHw39s1\nRErRQP6Z/YhZLZn9T7jZni4xMAz1/Kx3ZRPh9tbCS8v0d4Plz9RO+Hn1TnjpRkWy\nWqidtW3P9Nu6p6760WhdHRBc33Y8cKryztoiwEN+UqraW2QRaBZxpjwjZXhu4746\nweSzEqBJAgMBAAECggEAImHNjJUrB43FnXX+OhIXDy47qclCKc+3CZQIPGHB+GxL\nwFUN1735TlKr4J4Mi3+cL2UvRx49kXKL8xKL47Va2kFMvlwt5/V3RvElW0iKeakk\n+fvYFtrJ4YPYJjEAQ+mRfS1mcVufSJAoixDkb0MaZaGLdyHfT/VuRy7foxqnMW3d\n+eYjoKOQu6cYrRkSulxXd6hWcaygFAcpFOlB/MEC3JJAWwTbXoPRwyqOFc1UTqxb\nA9Rw3Qisf5If23KjsMhkySNmIZpwKI6HynT8wLf+JwtQb6kBWghhNRZlV3sAPi7V\ng0b4dKeyjW9oPHpgcVrVCoPcZD6WN191CRsHtcqF7wKBgQDX7MV307yNlVc1Ywyv\n4Bpd1ADZkCNU9QMq8xOc6vORrduYLv/artPS2dd9XQveUuqtFUkM21G08ep6pypH\nPwhL+wfH6EmurvWJ8nNJwM8rbDu2GdfBXHdQakn/FY2TVn0/JRGRX4uxPg4brg3G\nyfHuc3LsGJ9h3LPl4EJQVpOA4wKBgQDERJvyVljHnI3tFfwojDKVe/Lx22VFT06Y\nRwlAQqXdvQbY3c5sRfhQqE4oR1Ii9RjBYWLfRGFgg5YX1+LBQ/jTdriw1AySRnO/\nJQb0OqtudurDCgEei78wfjvr3Pc4T0NBfALb0SX5AQcmLoBlfcqmxMXwTMUobf0E\n7SizgC394wKBgCU/+YkEhCDAxLy3C7dDqAjLzCG8VfwQEK3WJWoKtl5JafrFEOp/\nApf7cMt1w9hk4Ytegq2vIFdg7JygPhDwdNP2yI7aKevyzvUvWGg+1P6eu2Zc6vW2\np/a9f2p8I2Jpp02/bVpw6z52gCSSUFoc+Jm0XP0R+lz9oCNmHsqRRP0VAoGAEy/9\n6vAkF8enXGFE2JX0MAcBFkHzoZYCQgLX1a9uVcIwFC2DC7S/dOsvUOLAFejONcGW\nj+s4dd4HY2dYpLpHbizyG19vpzE6CZrOFjxSHCeGBfFJcLoKw+vjYcilZpzhH1rH\nUMQeqTw/fa0YIlPs5ubfzwFCWRR/ouaojvveAy8CgYBKgZ95EzHpzOdQhvxeyh/M\nF7Vh84QmRoJd1bcIKGNIlAYLtDLyYXEJWkNewbChTmruzCPSxzA3A/OKeK+IRs3o\n8WJP91rpiVXv7HQiS4OO3vnmgW7J+2twKaserPle3hevCIUPMiGylHuR+P1Ar0Jd\no0vlNkyjfTpUAksyuymV5Q==\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

function getRandomInt(max) {
  return Math.floor(Math.random() * 3) + 1;
}

class Home extends React.Component { 

  //the dynamic states that'll render on the page
  constructor() {
    super();
    this.state = {
      page: "DEFAULT",
      musicProvider: "Spotify",
      words: [],
      images: [one, two, three, four],
      books: [],
      videos: [],
      spotify: null,
      appleMusic: null,
      soundcloud: null,
      homePageImage: five,
      textIdx: 0,
      headerLineOne: null,
      headerLineTwo: null,
      mailingListHeader: null,
      mailingPlaceholder: null,
      wordReject: null,
      wordAccept: null,
      mailReject: null,
      mailAccept: null,
      footer: null,
      alertSongImage: null,
      alertSongSpotify: null,
      alertSongAppleMusic: null,
      alertSongSoundcloud: null,
      alertVideo: null,
      alertTextLineOne: null,
      alertTextLineTwo: null,
      alertTextColor: '#000000',
      showPage: false,
      imagesAllLoaded: null,
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      loadingBackground: null,
      failedLoad: null,
    };

    this.submitWord = this.submitWord.bind(this)
    this.submitWordAlt = this.submitWordAlt.bind(this)
  } 

  //function to submit word, BUTTON
  submitWordAlt = () => {
    var val = document.getElementById("wordToSubmit").value
    if(val === "")
      {
        alert(this.state.wordReject)
      }
      else
      {
        doc.useServiceAccountAuth({
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });
    
        // loads document properties and worksheets-
        doc.loadInfo();
        
        var sheet = doc.sheetsById["0"];
        sheet.addRows([
          { Words: val,  VISIBLE: 'Y'}])
           
        alert(this.state.wordAccept)
        document.getElementById("wordToSubmit").value = ""

        setTimeout(() => {
          window.location.reload()
        }, 2000);
        
      }
      
  }

  //function to submitword, TYPED
  submitWord = (wordInput) => {
    //console.log(wordInput.key )
    if(wordInput.key === "Enter")
    {
      if(wordInput.target.value === "")
      {
        alert(this.state.wordReject)
      }
      else
      {

        doc.useServiceAccountAuth({
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });
    
        // loads document properties and worksheets-
         doc.loadInfo();
        
        var sheet = doc.sheetsById["0"];
        sheet.addRows([
          { Words: wordInput.target.value,  VISIBLE: 'Y'}])
           
        alert(this.state.wordAccept)
        wordInput.target.value = ""

        setTimeout(() => {
          window.location.reload()
        }, 2000);
      
      }
    }

  }

  componentDidMount()
  {
    

  }

  componentDidUnmount() {
    clearInterval(this.timeout);
  }
  
  pageSelect = (requestedPage) => {
    this.setState({page: requestedPage.target.innerText})
  }

  musicProviderSelect = (provider) => {
    this.setState({musicProvider: provider.target.innerText})
  }
  
  render
  ()

  
  {  
    

    //let textThatChanges = this.state.words[this.state.textIdx % this.state.words.length];
      
    return(   
      <div>  {/* DO NOT REMOVE THIS DIV COMPONENT*/}
      
        <div className="loadingDiv" style={{display: !this.state.showPage ? 'block' : 'none'}}>
          <img src={this.state.loadingBackground} className="loadingScreen" alt="Nick Chase Music loading screen"></img>
          
        </div>

      
        <div className="contentContainer" style={{display: this.state.showPage ? 'block' : 'none'}} >
          <br></br>
          <br></br>
          <center><h1 className='title'>ORLANDO KENNY</h1></center>
          <br></br>

          <Navbar bg="white" variant="light">
            <Container className="navBarContainer">
              <Nav onClick={e => e.preventDefault()} defaultActiveKey="HOME">
              &emsp;&emsp;<Nav.Link href="HOME" onClick={e => this.pageSelect(e)}>HOME</Nav.Link>&emsp;&emsp;
                &emsp;&emsp;<Nav.Link href="STREAM" onClick={e => this.pageSelect(e)}>MUSIC</Nav.Link>&emsp;&emsp;
                &emsp;&emsp;<Nav.Link href="TECH" onClick={e => this.pageSelect(e)} >TECH</Nav.Link>&emsp;&emsp;
               
               
              </Nav>
            </Container>
          </Navbar> {/**/}

          <br></br>

          {/*"HOME" PAGE*/}
          <div className="pageContainer" style={{display: (this.state.page === "HOME") || (this.state.page === 'DEFAULT') ? 'block' : 'none'}}>
            <div className="fades">
              <center> 
                {//FULL SCREEN ERROR LOAD, LOCALLY LOADED
                this.state.imagesAllLoaded === false && <h1>TRY RELOADING THE PAGE</h1>
                }

            

             
                   
                   
                
             
              {this.state.homePageImage !== null && <img src={this.state.homePageImage} alt="nickchase" onLoad={() => this.setState({showPage: true})} onError={() => {this.setState({showPage: true})
               /*alert("hmmm... the site failed to load, let's reload!")
                    window.location.reload()*/ }} ></img>}
                    <br></br>
                    <br></br>
                    <div className='text'>
                I have a deep love for both music and innovation.<br></br>
                <br></br>
                I want to get into this program to further my knowledge in both areas and merge the two.
                </div>

              </center>
              <br></br>
             
            </div>
          </div>


          



          <div className="pageContainer" style={{display: (this.state.page === "MUSIC") ? 'block' : 'none'}}>
            <div className="fades">
            <center>
              {/*<DropdownButton size="md" id="dropdown-basic-button" title={this.state.musicProvider} onClick={e => e.preventDefault()} style={{margin: '0 auto', marginTop: '10px'}}>
                  <Dropdown.Item onClick={e => this.musicProviderSelect(e)}>Spotify</Dropdown.Item>
                  <Dropdown.Item onClick={e => this.musicProviderSelect(e)}>Apple Music</Dropdown.Item>
                  <Dropdown.Item onClick={e => this.musicProviderSelect(e)}>Soundcloud</Dropdown.Item>
            </DropdownButton>
            <br></br>

            <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/5q70XbWzSCjlnxUstVSYzw?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            */}
<div className='text'>
I make rap music under the name <i>zl!ster</i> <br></br><br></br></div>

            <iframe src={"https://open.spotify.com/embed/artist/5q70XbWzSCjlnxUstVSYzw?utm_source=generator"} className="streaming" frameBorder="0" allowtransparency="true" allow="encrypted-media" title="Nick Chase Spotify"></iframe>
                <br></br>
              
                      <iframe src={"https://www.youtube.com/embed/bLOMqQoAQzc?si=0ME93nxeTIl2nydC"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
                
                      {this.state.images.map(item => (
               
                  <div className="column1">
                    <center>
                      <img src={item} style={{width: '100%'}} alt="Nick Chase Photos" onLoad={() => this.setState({imagesAllLoaded: true})} onError={() => this.setState({imagesAllLoaded: false})} ></img>
                      <br></br>
                      <br></br>
                    </center>
                  </div> 
                
                
                )  )}
                    {/*} {this.state.musicProvider === "Spotify" && <div>
                
                </div>}
              {this.state.musicProvider === "Apple Music" && <div></div>}
          {this.state.musicProvider === "Soundcloud" && <div></div>}*/}
            </center>
            </div>
            </div>
          <div className="pageContainer" style={{display: (this.state.page === "TECH") ? 'block' : 'none'}}>

            <div className="fades">
          

           <center>  <div className='text'>I am fortunate to have worked in<br></br> different sectors in the tech industry. <br></br> <br></br> I am most happy and proud of my work in different areas of innovation. <br></br><br></br>  In 2021, I started <a target="_blank" href="https://whatadamnsite.com">'What a Damn Site.'</a>, a front-end development service focused on making visual experiences for creators and innovators.  <br></br><br></br>I have worked with a wide range of folks, from lawyers to web3 entrepreneurs to record labels.<br></br><br></br>  Below are some examples: <br></br><br></br></div>
            
          

            <iframe src={"https://www.youtube.com/embed/U-jt1Efy_0E?si=hqluIa7SOGhCHbd-"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
               <p><center><i>a website for Diana Alvarez,<br></br> Film Photographer</i></center></p>
               <br></br>
              
              <iframe src={"https://www.youtube.com/embed/pYQM38bZKxk?si=EhMTjVPvcXv-5uTE"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
              <p><center><i>a website for mems,<br></br> a web3 community</i></center></p>
              <br></br>


              <iframe src={"https://www.youtube.com/embed/yRAMrFJHs0Y?si=8pmssp9QLsApqOHV"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
              <p><center><i>an augmented reality experience for <br></br> a brand activation in LA</i></center></p>
              <br></br>

              <iframe src={"https://www.youtube.com/embed/_135R3pkVQU?si"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
              <p><center><i>an augmented reality experience for <br></br> a brand activation in NYC</i></center></p>
              <br></br>

              <iframe src={"https://www.youtube.com/embed/fjP0wPXNrOs?si=q39lITGSu49n5i4S"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
              <p><center><i>a website for The Mellow Yellow,<br></br> an album by zl!ster</i></center></p>
              <br></br>

              <iframe src={"https://www.youtube.com/embed/aN4aD67GuyA?si=xHmRjUStbYjrLfiy"} title="zl!ster YouTube video player" alt="new Nick Chase music video out now!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   
              <p><center><i>the 'What a Damn Site.' storefront</i></center></p>
              <br></br>
              </center> 
              

              

             



              

              </div>
          
            </div>
            <br></br>

            <center>
              
            </center>
            <br></br>
          </div>
        </div>

      )
  }
}
export default Home;

